import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { FaEthereum, FaDollarSign, FaStar } from "react-icons/fa";
var Recaptcha = require("react-recaptcha");

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [captaVerified, setCapatVerified] = useState(false);
  const [feedback, setFeedback] = useState({
    text: "",
    color: "var(--secondary-text)",
  });
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // specifying your onload callback function
  var callback = function () {
    setCapatVerified(false);
  };

  // specifying verify callback function
  var verifyCallback = function (response) {
    if (response) setCapatVerified(true);
  };

  // specifying expired callback function
  var expiredCallback = function () {
    setCapatVerified(false);
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback({
      text: `Minting your ${CONFIG.NFT_NAME}...`,
      color: "var(--success-text)",
    });
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback({
          text: "Sorry, something went wrong please try again later.",
          color: "var(--error-text)",
        });
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback({
          text: `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`,
          color: "var(--success-text)",
        });
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const redirectToOpenSea = () => {
    window.open(
      CONFIG.MARKETPLACE_LINK,
      "_blank"
    );
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        {/* <StyledLogo alt={"logo"} src={"/config/images/logo.png"} /> */}
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={0.2} fd={"row"} ai={"center"} jc={"center"}>
          <s.Container flex={0.6} jc={"center"} ai={"center"}>
              <StyledImg alt={"example"} src={"/config/images/example.gif"} />
            </s.Container>
            <s.Container flex={1} jc={"center"} ai={"center"}>
              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 40,
                  color: "var(--accent-text)",
                }}
              >
                PHOENIX OF THE OCEANS
              </s.TextTitle>
              <s.SpacerSmall />
              <s.TextDescription
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  color: "var(--primary-text)",
                  marginLeft:"2rem",
                  marginRight:"2rem"
                }}
              >
                Phoenix of the Oceans is a collection of 12,709 unique ships,
                generated randomly from over 150+ assets. Each is a unique
                collection with different traits.
              </s.TextDescription>
              <s.SpacerMedium />
              <s.Container flex={1} jc={"center"} ai={"center"}>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? null : (
                  <Recaptcha
                    sitekey="6LextSsfAAAAAPcG85-YitVlj9gr9V7_KvqDdr7P"
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={callback}
                    expiredCallback={expiredCallback}
                    theme="dark"
                  />
                )}
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerMedium />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
                <s.SpacerSmall />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: feedback.color,
                  }}
                >
                  {feedback.text}
                </s.TextDescription>
              </s.Container>
              <s.SpacerSmall />
              <s.Container flex={1} fd={"row"} ai={"center"} jc={"center"}>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    CONNECT
                  </StyledButton>
                ) : (
                  <StyledButton
                    disabled={claimingNft || !captaVerified ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "BUSY" : "MINT"}
                  </StyledButton>
                )}
                <s.SpacerMedium />
                <StyledButton
                  style={{
                    backgroundColor: "blue",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    redirectToOpenSea();
                  }}
                >
                  OPENSEA
                </StyledButton>
              </s.Container>
            </s.Container>
            <s.Container flex={0.2} jc={"center"} ai={"center"}>
              <StyledImg alt={"example"} src={"/config/images/example.gif"} />
            </s.Container>
          </s.Container>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.Container flex={1} fd={"row"} ai={"center"}>
            <s.Container flex={1} ai={"center"}>
              <FaEthereum
                style={{
                  width: 25,
                  height: 25,
                  color: "var(--accent-text)",
                }}
              />
              <s.SpacerXSmall/>
              <s.TextTitle
                style={{
                  textAlign: "start",
                  fontSize: 20,
                  color: "var(--accent-text)",
                }}
              >
                {CONFIG.NETWORK.NAME} Mainnet
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "start",
                  fontSize: 15,
                  color: "var(--primary-text)",
                }}
              >
                Please make sure you are connected to the right network (
                {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please
                note: Once you make the purchase, you cannot undo this action.
              </s.TextDescription>
            </s.Container>
            <s.SpacerMedium />
            <s.Container flex={1} ai={"center"}>
              <FaDollarSign
                style={{
                  width: 25,
                  height: 25,
                  color: "var(--accent-text)",
                }}
              />
              <s.SpacerXSmall/>
              <s.TextTitle
                style={{
                  textAlign: "start",
                  fontSize: 20,
                  color: "var(--accent-text)",
                }}
              >
                Gas fees
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "start",
                  fontSize: 15,
                  color: "var(--primary-text)",
                }}
              >
                We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract
                to successfully mint your NFT. We recommend that you don't lower
                the gas limit.
              </s.TextDescription>
            </s.Container>
            <s.SpacerMedium />
            <s.Container flex={1} ai={"center"}>
              <FaStar
                style={{
                  width: 25,
                  height: 25,
                  color: "var(--accent-text)",
                }}
              />
              <s.SpacerXSmall/>
              <s.TextTitle
                style={{
                  textAlign: "start",
                  fontSize: 20,
                  color: "var(--accent-text)",
                }}
              >
                Unique
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "start",
                  fontSize: 15,
                  color: "var(--primary-text)",
                }}
              >
                NFT's are unique per pesron, only one NFT can be minted. You
                will have the rights to your NFT and will be able to do anything
                with it.
              </s.TextDescription>
            </s.Container>
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
